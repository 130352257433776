import React, { Component } from 'react';
import { breadCrumb, crudRequestConfig, dataMapper } from './config';
import SummaryDetails from './summaryDetails';
import PageHeader from '../../../base/PageHeader';
import { PanelStyled } from '../../../common/configuration';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { PanelCard, Button } from '../../../../v4/components';
import Table from './table/Table';
import { getUser } from '../../../../data/services';
import { USER_ROLE } from '../../../../data/enums';
import { clone } from '../../../../utils/arrayProcessor';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { getPermissionForReturns } from '../../../base/permission';
import withAlert from '../../../../utils/composition/withAlert';
import withLoading from '../../../../utils/composition/withLoading';
import { RETURN } from '../../../../data/enums/Route';
import ReturnStyled from '../ReturnStyled';
import { imageSliderListFormatter } from '../../../common/HelperFunctions';

class Details extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.subDUser =
      this.userInfo.roleId === USER_ROLE.DISTRIBUTOR_ADMIN || this.userInfo.roleId === USER_ROLE.BILLING_USER;
    this.state = {
      list: dataMapper({}),
      outletList: [],
      distributorList: [],
      enableErrorDisplay: false,
      lndEnabled: false,
      distributorServices: {
        billing: {
          status: false,
          url: '',
        },
      },
      distributor: {
        id: null,
        townList: [],
      },
      skus: [],
    };
    this.getData = () => {
      const { list } = this.state;
      return list;
    };
    const serverCall = {
      [EVENT_OPERATION.RECONCILED]: props.reconcileReturnRequest,
      [EVENT_OPERATION.REJECTED]: props.rejectReturnRequest,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handleSrnSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.permission = getPermissionForReturns();
  }

  componentDidMount() {
    this.getDistributorsList();
    this.getList();
    this.getSKUs();
  }

  directToMainPage = () => {
    /** direct to Srn page */
    const { history } = this.props;
    history.push(`/${RETURN}`);
  };

  handleSrnSuccess = (response, type) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus(
      {},
      {
        handleSuccess: response => {
          this.setState({ skus: response.data.skus.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setDistributorServices = distributor => {
    const { distributorServices, distributor: stateDistributor, list } = this.state;
    stateDistributor.townList = distributor?.Towns?.map(town => town.id) ?? [];
    stateDistributor.id = distributor?.id;
    this.setState(
      {
        distributorServices: { ...distributor.servicesUsed, id: distributor.id } || distributorServices,
        distributor: stateDistributor,
        lndEnabled: distributor.servicesUsed.logistic.status,
      },
      () => {
        localStorage.setItem('isBillingUser', JSON.stringify(distributor.servicesUsed?.billing?.status ?? false));
      },
    );
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          const distributorList = response.data.distributors ? response.data.distributors.rows || [] : [];
          this.setState(
            {
              distributorList,
            },
            () => {
              if (this.subDUser && distributorList.length > 0) {
                this.setDistributorServices(distributorList[0]);
              }
            },
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getList = () => {
    const { getSrnDetails, displayAlert, match } = this.props;
    getSrnDetails(
      {
        srnId: parseInt(match.params.id || '', 10),
      },
      {
        handleSuccess: response => {
          const { srnDetails = {} } = response.data;
          const modifiedData = dataMapper(srnDetails);
          this.setState({ list: modifiedData }, () => {
            if (!this.subDUser && modifiedData?.Distributor?.id) {
              this.setDistributorServices(modifiedData?.Distributor);
            }
          });
          this.setReconciliationData();
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  rejectReturn = () => {
    const { list } = this.state;
    this.onFormSubmit(EVENT_OPERATION.REJECTED, list);
  };

  onSubmit = type => {
    if (type === EVENT_OPERATION.REJECTED) {
      this.rejectReturn();
    } else if (type === EVENT_OPERATION.RECONCILED) {
      const valid = this.getValidationStatus();
      if (valid) {
        this.reconcileReturn();
      } else {
        this.setState({ enableErrorDisplay: true });
      }
    }
  };

  reconcileReturn = () => {
    const { list } = this.state;
    const updatedData = clone(list);
    const tableData = this.getTableDetails();
    updatedData.details = this.getDetails();
    updatedData.amount = tableData.totalAmount;
    updatedData.srnType = tableData.srnType;
    updatedData.returnOrders = tableData.returnOrders;
    updatedData.oldBillStatus = false;
    this.onFormSubmit(EVENT_OPERATION.RECONCILED, updatedData);
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();

    return detailsStatus && tableStatus;
  };

  responseProcessor = callBack => type => response => {
    callBack(response, type);
  };

  setReconciliationData = () => {
    const { list } = this.state;
    list.orders.forEach(order => {
      if (order.verifiedQuantity < order.returnQuantity) {
        order.reconciliationColor = 'red';
      } else if (order.quantity === order.verifiedQuantity) {
        order.reconciliationColor = 'green';
      }
      if (order.damagedImages) {
        order.damagedImages = [...(imageSliderListFormatter(order.damagedImages) || [])];
      }
      if (order.expiredImages) {
        order.expiredImages = [...(imageSliderListFormatter(order.expiredImages) || [])];
      }
    });
    this.setState({ list });
  };

  render() {
    const { outletList, distributorList, enableErrorDisplay, distributorServices, skus, list, lndEnabled } = this.state;
    const { serverResponseWaiting, getSKUBatchDetail, validateSrnData } = this.props;
    return (
      <ReturnStyled>
        <div className="section-header">
          <PanelStyled>
            <div className="prn-page-header">
              <PageHeader
                breadCrumb={breadCrumb}
                config={{
                  title: '-',
                }}
              />
              <div className="flex m-0">
                <Button secondary small onClick={() => this.directToMainPage()}>
                  <span> Cancel</span>
                </Button>
                <div className={list?.inboundPending ? 'audit-blocked' : ''}>
                  <Button
                    secondary
                    small
                    className="reject"
                    disabled={list?.inboundPending || lndEnabled || !this.permission.update}
                    onClick={() => this.onSubmit(EVENT_OPERATION.REJECTED)}
                  >
                    <span> Reject</span>
                  </Button>
                </div>
                <div className={list?.inboundPending ? 'audit-blocked' : ''}>
                  <Button
                    primary
                    small
                    disabled={list?.inboundPending || !this.permission.update}
                    onClick={() => this.onSubmit(EVENT_OPERATION.RECONCILED)}
                  >
                    <span> Reconcile</span>
                  </Button>
                </div>
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <PanelCard cardTitle="details" className={this.permission.create ? '' : 'disabled'}>
            <SummaryDetails
              pending
              enableErrorDisplay={enableErrorDisplay}
              loading={serverResponseWaiting}
              outletList={outletList}
              distributorList={distributorList}
              updateDistributorServices={this.setDistributorServices}
              data={list}
              pendingcustomerList={list.Customer}
              subDUser={this.subDUser}
              distributorServices={distributorServices}
              getStatus={childMethod => (this.getDetailsValidationStatus = childMethod)}
              getDetails={childMethod => (this.getDetails = childMethod)}
            />
          </PanelCard>
          <PanelCard cardTitle="sku" skuClassStatus>
            <Table
              data={list}
              skuList={skus}
              enableErrorDisplay={enableErrorDisplay}
              getStatus={childMethod => (this.getTableValidationStatus = childMethod)}
              getDetails={childMethod => (this.getTableDetails = childMethod)}
              getSKUBatchDetail={getSKUBatchDetail}
              validateSrnData={validateSrnData}
              permission={this.permission}
            />
          </PanelCard>
        </div>
      </ReturnStyled>
    );
  }
}

export default withLoading(withAlert()(Details));
